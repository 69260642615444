import {customElement, property, query} from 'lit/decorators.js';
import {css, html, LitElement, PropertyValueMap} from 'lit';
import {installMediaQueryWatcher} from 'pwa-helpers/media-query.js';
import {installOfflineWatcher} from 'pwa-helpers/network.js';
import {installRouter} from 'pwa-helpers/router.js';
import {updateMetadata} from 'pwa-helpers/metadata.js';
import {RootState, store} from '../store';
import {navigate, updateDrawer, updateOfflineThunk} from '../state/app-slice';
import {menuIcon} from './my-icons';
import {connect} from "./connected-component";
import '@polymer/app-layout/app-drawer/app-drawer.js';
import '@polymer/app-layout/app-header/app-header.js';
import '@polymer/app-layout/app-scroll-effects/effects/waterfall.js';
import '@polymer/app-layout/app-toolbar/app-toolbar.js';

@customElement('my-app')
class MyApp extends connect(store)(LitElement) {
    @property({type: String}) appTitle: string = "";
    @property({type: String}) _page: string = "";
    @property({type: Boolean}) _drawerOpened: boolean = false;
    @property({type: Boolean}) _snackbarOpened: boolean = false;
    @property({type: Boolean}) _offline: boolean = false;
    @property({type: String}) theme: string = "";

    static override get styles() {
        return [
            css`
                :host {
                    display: block;
                    --app-drawer-width: 256px;
                    --app-light-text-color: rgb(221, 200, 196);
                    --app-dark-text-color: rgb(88, 75, 83);
                    --app-secondary-dark-text-color: rgb(137, 106, 103);
                    --app-primary-color: rgb(88, 75, 83);
                    --app-primary-container-color: rgb(151, 128, 142);
                    --app-primary-background-color: var(--app-primary-color);
                    --app-secondary-color: rgb(137, 106, 103);
                    --app-secondary-container-color: rgb(198, 152, 147);
                    --app-secondary-background-color: var(--app-secondary-color);
                    --app-tertiary-color: rgb(251, 240, 232);
                    --app-tertiary-container-color: rgb(251, 240, 232);
                    --app-tertiary-background-color: var(--app-tertiary-color);
                    --app-quaternary-color: rgb(255, 250, 246);
                    --app-quaternary-background-color: var(--app-quaternary-color);
                    --app-map-color: var(--app-light-text-color);
                    --app-line-color: var(--app-dark-text-color);
                    --app-section-even-color: #f7f7f7;
                    --app-section-odd-color: var(--app-light-text-color);
                }

                app-header {
                    z-index: 3;
                    display: grid;
                }

                app-toolbar {
                    display: grid;
                    grid-template-columns: min-content min-content auto;
                    grid-template-rows: min-content;
                }

                .toolbar-top {
                    background-color: var(--app-primary-color);
                }

                [main-title] {
                    color: var(--app-light-text-color);
                    font-family: 'Pacifico';
                    text-transform: lowercase;
                    font-size: 30px;
                }

                .toolbar-list {
                    display: none;
                }

                .toolbar-list > a {
                    color: var(--app-light-text-color);
                    text-decoration: none;
                    font-size: 18px;
                    padding: 18px 19px;
                    box-sizing: border-box;
                }

                .toolbar-list > a[selected] {
                    color: var(--app-light-text-color);
                    border-bottom: 4px solid var(--app-light-text-color);
                }

                .menu-btn {
                    background: none;
                    border: none;
                    fill: var(--app-light-text-color);
                    cursor: pointer;
                    height: 44px;
                    width: 44px;
                }

                .drawer-list {
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding: 24px;
                    background: var(--app-secondary-background-color);
                    position: relative;
                }

                .drawer-list > a {
                    display: block;
                    text-decoration: none;
                    color: var(--app-drawer-text-color);
                    line-height: 40px;
                    padding: 0 24px;
                }

                .drawer-list > a[selected] {
                    color: var(--app-light-text-color);
                }

                /* Workaround for IE11 displaying <main> as inline */

                main {
                    display: block;
                }

                .page {
                    display: none;
                }

                .page[active] {
                    display: block;
                }

                footer {
                    padding: 24px;
                    background: var(--app-secondary-background-color);
                    color: var(--app-light-text-color);
                    text-align: center;
                }

                @media (min-width: 460px) {
                    app-toolbar {
                        grid-template-columns: min-content 1fr;
                    }

                    .toolbar-list {
                        display: inline-flex;
                        flex-flow: row nowrap;
                        justify-content: flex-end;
                    }

                    .menu-btn {
                        display: none;
                    }

                    [main-title] {
                        padding-right: 0px;
                    }
                }
            `
        ];
    }

    override render() {
        // Anything that's related to rendering should be done in here.
        return html`
            <!-- Header -->
            <app-header data-theme="${this.theme}" condenses reveals shadow>
                <app-toolbar class="toolbar-top">
                    <button class="menu-btn" title="Menu" @click="${this._menuButtonClicked}">${menuIcon}</button>
                    <div main-title>${this.appTitle}</div>
                    <nav class="toolbar-list">
                        <a ?selected="${this._page === 'landing'}" href="/landing">Home</a>
                        <a ?selected="${this._page === 'map'}" href="/campaign">New Map</a>
                        <a ?selected="${this._page === 'games'}" href="/games">My Games</a>
                        <a ?selected="${this._page === 'account'}" href="/account">Account</a>
                    </nav>
                </app-toolbar>
            </app-header>

            <app-drawer
                    data-theme="${this.theme}"
                    .opened="${this._drawerOpened}"
                    @opened-changed="${this._drawerOpenedChanged}">
                <nav class="drawer-list">
                    <a ?selected="${this._page === 'landing'}" href="/landing">Home</a>
                    <a ?selected="${this._page === 'map'}" href="/campaign">New Map</a>
                    <a ?selected="${this._page === 'games'}" href="/games">My Games</a>
                    <a ?selected="${this._page === 'account'}" href="/account">Account</a>
                </nav>
            </app-drawer>

            <main role="main" data-theme="${this.theme}" class="main-content">
                <landing-page class="page" ?active="${this._page === 'landing'}"></landing-page>
                <map-page class="page" ?active="${this._page === 'campaign'}"></map-page>
                <games-page class="page" ?active="${this._page === 'games'}"></games-page>
                <my-account class="page" ?active="${this._page === 'account'}"></my-account>
                <my-view404 class="page" ?active="${this._page === 'view404'}"></my-view404>
            </main>
        `;
    }

    @query('.mdc-top-app-bar') topBar!: HTMLElement;

    override firstUpdated() {
        installRouter((location) => store.dispatch(navigate(decodeURIComponent(location.pathname))));
        installOfflineWatcher((offline) => store.dispatch(updateOfflineThunk(offline)));
        installMediaQueryWatcher(`(min-width: 460px)`,
            () => store.dispatch(updateDrawer(false)));
    }

    override updated(changedProps: PropertyValueMap<never>) {
        if (changedProps.has('_page')) {
            const pageTitle = this.appTitle + ' - ' + this._page;
            updateMetadata({
                title: pageTitle,
                description: pageTitle
                // This object also takes an image property, that points to an img src.
            });
        }
    }

    _menuButtonClicked() {
        store.dispatch(updateDrawer(true));
    }

    _drawerOpenedChanged(e: unknown) {
        const event = e as {target: {opened: boolean}};
        store.dispatch(updateDrawer(event.target.opened));
    }

    override stateChanged(state: RootState) {
        this._page = state.app.page;
        this._offline = state.app.offline;
        this._snackbarOpened = state.app.snackbarOpened;
        this._drawerOpened = state.app.drawerOpened;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'my-app': MyApp;
    }
}